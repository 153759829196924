import axios from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { backendBaseUrl } from '../../backendConfig.json';
import { UserData } from 'src/types/UserData';

export const useDeleteUserMutation = ({
    onSuccess = () => null,
    onError = () => null,
}: {
    onSuccess: () => void;
    onError: () => void;
}): UseMutationResult<UserData, unknown, Record<string, string>> => {
    const queryClient = useQueryClient();

    return useMutation(
        ({ userId, customerIdOverride }) => {
            if (customerIdOverride) {
                return axios.delete(
                    `https://${backendBaseUrl}/customers/${customerIdOverride}/users/${userId}`,
                );
            }

            return axios.delete(`https://${backendBaseUrl}/users/${userId}`);
        },
        {
            onSuccess: () => {
                ['users', 'customerUsage'].forEach((key) =>
                    queryClient.invalidateQueries(key),
                );
                onSuccess();
            },
            onError,
        },
    );
};
