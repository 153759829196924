import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Paragraph } from '_atoms';
import { Alert, WorkflowError } from '_molecules';
import { SocialMediaPosts } from '_organisms';
import { ResultsProps } from '../Results';
import ShadowDragonLogo from '../../../images/shadow-dragon-logo.png';
import { useSocialMediaErrors } from './useSocialMediaErrors';
import { SocialMediaErrors } from './SocialMediaErrors';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';

export const SocialMedia: FC<ResultsProps> = ({
    accessToken,
    targetData,
    caseData,
    entityIndicators,
}: ResultsProps) => {
    const { t } = useTranslation();
    const socialMediaErrors = useSocialMediaErrors();

    return (
        <DefaultPageLayout title={t('rawData.socialMedia')}>
            <div className="flex gap-4">
                <div className="flex-grow">
                    {targetData?.workflowErrors?.length ? (
                        <WorkflowError
                            className="my-8"
                            errors={targetData?.workflowErrors}
                            path="socialmedia"
                        />
                    ) : null}
                    {targetData?.status === 'HasInitialProfile' && (
                        <Alert
                            alertType="warning"
                            headline={t('profileDataNotFinal')}
                            className="my-8"
                        />
                    )}
                    {Boolean(socialMediaErrors.size) && (
                        <SocialMediaErrors errors={socialMediaErrors} />
                    )}
                </div>
                <div className="flex flex-col justify-center gap-1 text-center mr-1 basis-28 self-center">
                    <Paragraph size="small" color="default">
                        {t('socialMedia.attribution')}
                    </Paragraph>
                    <Image src={ShadowDragonLogo} alt="shadow dragon logo" />
                </div>
            </div>
            <SocialMediaPosts
                accessToken={accessToken}
                targetData={targetData}
                caseData={caseData}
                entityIndicators={entityIndicators}
            />
        </DefaultPageLayout>
    );
};
