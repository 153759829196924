import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Timeline, {
    DateHeader,
    SidebarHeader,
    TimelineHeaders,
} from 'react-calendar-timeline';
import { InfoBlock } from '_molecules';

import 'react-calendar-timeline/lib/Timeline.css';
import './CvTimeline.css';
import { EntrepreneurialActivities } from '_types';
import { ProfileDataEducation } from '../../../../../services/dataService';
import { getTimeline } from './helpers';

interface CvTimelineProps {
    startTime: number;
    endTime: number;
    companies?: EntrepreneurialActivities;
    educations?: ProfileDataEducation[];
}

export const CvTimeline = (props: CvTimelineProps): React.ReactElement => {
    const { startTime, endTime, companies, educations } = props;
    const { t } = useTranslation();

    const defaultTimeRange = endTime - startTime;

    const { groups, items } = useMemo(
        () => getTimeline({ companies, educations }),
        [companies, educations],
    );

    return (
        <div className="mt-5">
            {groups.length ? (
                <Timeline
                    groups={groups}
                    items={items}
                    visibleTimeStart={startTime}
                    visibleTimeEnd={endTime}
                    minZoom={defaultTimeRange}
                    maxZoom={defaultTimeRange}
                    itemTouchSendsClick={false}
                    stackItems={true}
                    canMove={false}
                    canResize={false}
                    sidebarWidth={350}
                    lineHeight={60}
                    itemHeightRatio={0.8}
                    className="border-b-2 border-r-2 border-gray-600"
                    onTimeChange={(_start, _end, _updateScrollCanvas) => {
                        _updateScrollCanvas(startTime, endTime);
                    }}
                >
                    <TimelineHeaders className="sticky-timeline-header">
                        <SidebarHeader>
                            {({ getRootProps }) => (
                                <div
                                    className="pt-4 pl-3 text-label text-font-light"
                                    {...getRootProps()}
                                >
                                    {t('companyEducation')}
                                </div>
                            )}
                        </SidebarHeader>
                        <DateHeader unit="primaryHeader" />
                        <DateHeader />
                    </TimelineHeaders>
                </Timeline>
            ) : (
                <InfoBlock headline={t('')} content={[]} />
            )}
        </div>
    );
};
