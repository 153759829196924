import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, Redirect, useParams } from 'react-router-dom';
import { TwoColumnPageLayout } from '../../../components/Layout/TwoColumnPageLayout';
import { useCreateTargetMutation } from '_queries';
import { useTranslation } from 'react-i18next';
import { Button, Paragraph } from '_atoms';
import {
    ExtendedSubmitHandler,
    FormSchema,
    FormType,
} from './wizard/form.interface';
import { CVUploader } from '../../../components/_molecules/CVUploader';
import { routes } from '../../../routes';
import { DynamicForm } from './wizard/DynamicForm';
import {
    formConfigMap,
    personalDetailsFormConfig,
} from './wizard/form-configs';
import { ShowForm } from './wizard/ShowForm';
import {
    mapFormSchemaToUserInputTarget,
    mapUserInputTargetToFormSchema,
} from './wizard/utils';
import { sanitizeInput } from '@indicium/common/src/utils';
import { ProcessTarget, UserInputTarget } from '@indicium/common';
import { TargetFormSchema } from '../../../schemas/targetFormSchema';
import { useTargetCreationStateStore } from '../../../state/useStore';
import { TargetSummary } from './wizard/TargetSummary';
import { MdDeleteOutline } from 'react-icons/md';
import { PiReadCvLogo } from 'react-icons/pi';
import { WizardCard } from '../../../components/_atoms/Card/WizardCard';
import { Title } from '@indicium/common/src/types/Title';
import { ConfirmationModal } from '_molecules';
import { useToast } from '../../../context/Toast';

const mockData: FormSchema = {
    personalDetails: {
        title: Title.prof,
        firstName: 'John',
        lastName: 'Doe',
        middleName: 'A',
        gender: 'male',
    },
    birthInfo: {
        dateOfBirth: '1990-01-01',
        placeOfBirth: 'Cityville',
        countryOfBirth: 'US',
    },
    residentialInfo: {
        countryOfResidence: 'DE',
    },
    contactEmails: [{ email: 'john.doe@example.com' }],
    contactPhones: [{ phone: '+1234567890' }],
    contactWebsites: [{ website: 'https://johndoe.com' }],
    facebookLinks: [{ link: 'john.doe' }],
    instagramLinks: [{ link: 'john.doe' }],
    linkedinLinks: [{ link: 'john-doe' }],
    twitterLinks: [{ link: 'johndoe' }],
    xingLinks: [{ link: 'john.doe' }],
    nationalities: [{ nationality: 'US' }, { nationality: 'DE' }],
    jobs: [
        {
            jobTitle: 'Software Engineer',
            jobDate: { start: '2015-01-01', end: '2020-01-01' },
            selfEmployed: false,
            companyName: 'TechCorp',
            companyWebsite: 'https://techcorp.com',
            companyVatNumber: '123456789',
            companyCommercialRegisterNumber: '987654321',
            companyCountry: 'Countryland',
            companyCity: 'Cityville',
        },
        {
            jobTitle: 'Senior Developer',
            jobDate: { start: '2020-02-01', end: '' },
            selfEmployed: false,
            companyName: 'DevSolutions',
            companyWebsite: 'https://devsolutions.com',
            companyVatNumber: '987654321',
            companyCommercialRegisterNumber: '123456789',
            companyCountry: 'Otherland',
            companyCity: 'Townsville',
        },
    ],
    educationInfo: [
        {
            title: 'Bachelor of Science',
            type: 'Computer Science',
            date: { start: '2008-09-01', end: '2012-06-01' },
            institutionName: 'University of Tech',
            institutionLocation: 'Cityville',
        },
        {
            title: 'Master of Science',
            type: 'Software Engineering',
            date: { start: '2012-09-01', end: '2014-06-01' },
            institutionName: 'Institute of Technology',
            institutionLocation: 'Townsville',
        },
    ],
    keywords: [
        {
            value: 'https://personalblog.com',
            included: true,
        },
        {
            value: 'https://portfolio.com',
            included: true,
        },
        {
            value: 'https://excludedsite1.com',
            included: false,
        },
        {
            value: 'https://excludedsite2.com',
            included: false,
        },
    ],
};

export const TargetNewV2: FC = () => {
    const { t } = useTranslation();

    const [currentlyVisibleForm, setCurrentlyVisibleForm] =
        useState<FormType | null>(null);

    const { caseId } =
        useParams<{
            caseId: string;
        }>();

    const { setTargetFormData, clearCandidateData, getTargetFormData } =
        useTargetCreationStateStore();

    const { formData: formDataFromStore, cvImages: setCvImagesFromStore } =
        getTargetFormData(caseId);

    const [formData, setFormData] =
        useState<Partial<FormSchema>>(formDataFromStore);

    const [cvImages, setCvImages] = useState<string[]>(setCvImagesFromStore);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [editIndex, setEditIndex] = useState<number>(0);

    const toast = useToast();

    const createTargetMutation = useCreateTargetMutation();

    useEffect(() => {
        if (createTargetMutation.isSuccess) {
            clearCandidateData(caseId);
        }
    }, [createTargetMutation.isSuccess, clearCandidateData, caseId]);

    const handleSubmit = useCallback(
        async (values: Partial<FormSchema>) => {
            const dataToSubmit = mapFormSchemaToUserInputTarget(values);
            try {
                const data = sanitizeInput<UserInputTarget>(
                    (await TargetFormSchema.validate(
                        dataToSubmit,
                    )) as UserInputTarget,
                );
                if (data) {
                    createTargetMutation.mutate(
                        {
                            caseId,
                            data,
                            cvImages,
                        },
                        {
                            onError: (e) => {
                                console.error(e);
                                toast({
                                    style: 'error',
                                    text: t('invalidServerResponse'),
                                    ms: 5000,
                                });
                            },
                        },
                    );
                }
            } catch (err) {
                toast({
                    style: 'error',
                    text: t('apiErrors.default'),
                    ms: 5000,
                });
            }
        },
        [createTargetMutation, caseId, cvImages, toast, t],
    );

    const [_dropping, setDropping] = useState(false);

    // TODO: move to separate component
    const Intro: FC = useCallback(() => {
        return (
            <div className="flex flex-col items-center justify-center w-full h-full gap-4">
                <Paragraph color="dark">
                    {t('targetWizard.targetCreationInstructionMsg')}
                </Paragraph>

                <div className="bg-gray-200 h-40 w-40 rounded-full relative text-center">
                    <PiReadCvLogo className="absolute inset-0 w-full h-full text-gray-400" />
                </div>

                <CVUploader
                    customView={{
                        openElement(open) {
                            return (
                                <Button
                                    level="secondary"
                                    onClick={open}
                                    className="w-52"
                                    type="reset"
                                >
                                    {t('uploadPdf')}
                                </Button>
                            );
                        },
                    }}
                    onUpload={(target, images) => {
                        /*
                         * TODO: investigate reason why dates may be present in props from the "__original" key
                         *  but are not in the actual target data. e.g. "Job" date ranges... issue comes from nest-API
                         */
                        const formDataFromResume =
                            mapUserInputTargetToFormSchema(
                                (target ?? {}) as ProcessTarget,
                            );
                        setFormData(formDataFromResume);
                        setCvImages(images ?? []);
                        setTargetFormData(caseId, {
                            formData: formDataFromResume,
                            cvImages: images ?? [],
                        });
                    }}
                    onError={(error) => {
                        alert(
                            t('cvUploadFailed') +
                                '\n' +
                                (typeof error === 'object' &&
                                error instanceof Error
                                    ? error.message
                                    : String(error)),
                        );
                    }}
                />
            </div>
        );
    }, [t, caseId, setTargetFormData]);

    const editEntry = (key: FormType, index?: number) => {
        if (index !== undefined) {
            setEditIndex(index);
        }

        setEditMode(true);
        setCurrentlyVisibleForm(key);
    };

    const introDone =
        formData &&
        formData.personalDetails?.firstName &&
        formData.personalDetails?.lastName;

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const resetForm = useCallback(() => {
        setFormData({});
        setCvImages([]);
        clearCandidateData(caseId);
        setEditMode(false);
        setEditIndex(0);
        setCurrentlyVisibleForm(null);
    }, [clearCandidateData, caseId]);

    const handleDeleteConfirmationModalChoice = useCallback(
        (choice: 'confirm' | 'cancel') => {
            if (choice === 'confirm') {
                resetForm();
            }

            setShowDeleteConfirmation(false);
        },
        [resetForm],
    );

    const loadMockData = useCallback(() => {
        setFormData(mockData);
        setTargetFormData(caseId, {
            formData: mockData,
            cvImages: [],
        });
    }, [caseId, setTargetFormData]);

    const onSubmit: ExtendedSubmitHandler<Partial<FormSchema>> = useCallback(
        (data, _event, addNewEntry = false) => {
            setFormData(data);
            setTargetFormData(caseId, { formData: data, cvImages });
            setEditMode(false);
            setEditIndex(0);
            if (!addNewEntry) {
                setCurrentlyVisibleForm(null);
            }
        },
        [caseId, cvImages, setTargetFormData],
    );

    // TODO: move to separate component
    const rightContent = useMemo(
        () => (
            <>
                {!introDone ? (
                    // TODO: this should actually show a message saying CV data will show up here when it exists. The option to decide if to upload cv or fill the form manually should be moved to the left pane and shown first instead of the "personalDetails" form.
                    <Intro />
                ) : (
                    <div className="flex flex-col w-full h-full gap-4 flex-grow">
                        <TargetSummary
                            data={formData}
                            cvImages={cvImages}
                            onUpdate={onSubmit}
                            editHandler={editEntry}
                        />
                        <ConfirmationModal
                            isOpen={showDeleteConfirmation}
                            handleButtonClick={
                                handleDeleteConfirmationModalChoice
                            }
                            confirmButtonText={t('delete')}
                            hideCloseButton={true}
                            title={t('targetWizard.resetFormConfirmation')}
                        />
                    </div>
                )}
            </>
        ),
        [
            introDone,
            Intro,
            t,
            formData,
            cvImages,
            onSubmit,
            showDeleteConfirmation,
            handleDeleteConfirmationModalChoice,
        ],
    );

    // TODO: move to separate component
    const leftContent = (
        <>
            {!introDone ? (
                <DynamicForm
                    fields={personalDetailsFormConfig}
                    parentKey="personalDetails"
                    onSubmit={onSubmit}
                />
            ) : currentlyVisibleForm === null ? (
                <div className="flex flex-col items-center gap-6 overflow-hidden">
                    <Paragraph color="light">
                        {t('targetWizard.formInstructionMsg')}
                    </Paragraph>

                    <div className="grid grid-cols-3 gap-4 flex-grow overflow-y-auto">
                        {Object.entries(formConfigMap).map(([key, value]) => (
                            <WizardCard
                                key={key}
                                title={t(`targetWizard.fields.${key}.title`)}
                                icon={value.icon}
                                description={t(
                                    `targetWizard.fields.${key}.description`,
                                    '',
                                )}
                                onClick={() => {
                                    setCurrentlyVisibleForm(key as FormType);
                                }}
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <ShowForm
                    formValues={formData}
                    selectedFormType={currentlyVisibleForm}
                    onSubmit={onSubmit}
                    onCancel={() => {
                        setEditMode(false);
                        setEditIndex(0);
                        setCurrentlyVisibleForm(null);
                    }}
                    editMode={editMode}
                    editIndex={editIndex}
                />
            )}
        </>
    );

    if (createTargetMutation.isSuccess) {
        return (
            <Redirect
                to={generatePath(routes.targetShow.path, {
                    caseId,
                    targetId: createTargetMutation.data.id,
                })}
            />
        );
    }

    return (
        <TwoColumnPageLayout
            title={t('targetWizard.headline')}
            headerActions={
                <div className="flex justify-end space-x-4">
                    {process.env.REACT_APP_STAGE !== 'production' && (
                        <Button
                            level="secondary"
                            onClick={loadMockData}
                            aria-label={'delete'}
                            className="self-start"
                        >
                            {'Load mock data'}
                        </Button>
                    )}
                    <Button
                        level="errorGhost"
                        type="button"
                        icon={MdDeleteOutline}
                        onClick={() => setShowDeleteConfirmation(true)}
                        aria-label={'delete'}
                        disabled={Object.keys(formData).length === 0}
                    >
                        {t('targetWizard.resetForm')}
                    </Button>

                    <Button
                        className="justify-self-start align-middle"
                        level="primary"
                        disabled={createTargetMutation.isLoading}
                        onClick={() => handleSubmit(formData)}
                        type="submit"
                    >
                        {t('targetWizard.createTargetBtn')}
                    </Button>
                </div>
            }
            leftContent={leftContent}
            rightContent={rightContent}
            rightBgClassName="bg-neutral-300"
            onDragEnter={() => setDropping(true)}
            onDragLeave={() => setDropping(false)}
        />
    );
};
