import { nonProdDataTestId } from '_utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdAddCircleOutline } from 'react-icons/md';
import { UserTable } from '_organisms/UserTable';
import { UserData } from 'src/types/UserData';
import { routes } from '../../../routes';
import {
    Button,
    Checkbox,
    Error,
    Input,
    LoadingSpinner,
    Modal,
    Select,
} from '_atoms';
import { Alert, ConfirmationModal } from '_molecules';
import {
    useCreateUserMutation,
    useCustomerUsageQuery,
    useDeleteUserMutation,
} from '_queries';
import { useDeleteMutation } from '../../../hooks/useDeleteMutation';
import { useUserState } from '../../../context/User';
import {
    USER_GROUP_ADMINISTRATORS,
    USER_GROUP_SUPER_USERS,
} from '../../../services/authenticationService';
import { useUpdateUserMutation } from '../../../hooks/queries/useUpdateUserMutation';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { useToast } from '../../../context/Toast';

export const UserAdmin: FC<{ customerIdOverride: string }> = ({
    customerIdOverride,
}) => {
    const { t } = useTranslation();
    const toast = useToast();

    const [{ userRoles, customerId }] = useUserState();

    const editingCustomerUsers = !!customerIdOverride;

    const {
        data: { users } = {
            users: [],
            total: { totalCases: 0, activeCreatedTargets: 0, totalTargets: 0 },
        },
        ...usersQuery
    } = useCustomerUsageQuery(customerIdOverride || customerId);

    const createUserMutation = useCreateUserMutation();
    const updateUserMutation = useUpdateUserMutation();
    const deleteUserMutation = useDeleteUserMutation({
        onSuccess: () =>
            toast({
                text: t('toasts.deleteUser'),
                style: 'confirmation',
                ms: 5000,
            }),
        onError: () =>
            toast({
                text: t('toasts.deleteUserFailed'),
                style: 'error',
                ms: 5000,
            }),
    });

    const [{ enableConfirm, showModal }, dispatch] = useDeleteMutation();

    const [showNewEntry, setShowNewEntry] = useState(false);

    const [newUser, setNewUser] = useState<UserData>({ email: '' });

    if (usersQuery.isError) {
        console.error('No results found: ', usersQuery.error);
    }

    const handleModalEvent = (event: 'confirm' | 'cancel') => {
        switch (event) {
            case 'confirm':
                dispatch({
                    type: 'CONFIRM_DELETION',
                    mutation: deleteUserMutation.mutate,
                });
                break;
            case 'cancel':
                dispatch({ type: 'RESET' });
                break;
        }
    };

    const handleUpdateUser = (userId: string) => {
        const userToBeChanged = users?.find((user) => user.id === userId);
        updateUserMutation.mutate(
            {
                userId,
                customerIdOverride,
                data: {
                    isAdmin:
                        userToBeChanged?.privilegeLevel !== 'CustomerAdmin',
                },
            },
            {
                onSettled: () => {
                    toast({
                        text: t('toasts.userUpdated'),
                        style: 'confirmation',
                        ms: 5000,
                    });
                },
                onSuccess: () => {
                    if (userToBeChanged) {
                        userToBeChanged.isAdmin = !userToBeChanged.isAdmin;
                    }
                },
            },
        );
    };

    const tableRowActions =
        userRoles.includes(USER_GROUP_ADMINISTRATORS) ||
        userRoles.includes(USER_GROUP_SUPER_USERS)
            ? [
                  {
                      id: 'editUser',
                      label: (userId: string) => {
                          const userToBeChanged = users?.find(
                              (user) => user.id === userId,
                          );
                          if (
                              userToBeChanged?.privilegeLevel === 'CustomerUser'
                          ) {
                              return t('addAdminPermissionsAction');
                          }
                          return t('removeAdminPermissionsAction');
                      },
                      callback: (userId: string) => handleUpdateUser(userId),
                  },
                  {
                      id: 'deleteUser',
                      label: t('deleteUser.action'),
                      callback: (userId: string) => {
                          dispatch({
                              type: 'SET_DELETE_PARAMS',
                              params: { userId, customerIdOverride },
                          });
                      },
                  },
              ]
            : undefined;

    const handleAddNewUser = () => {
        createUserMutation.mutate(
            {
                customerIdOverride,
                data: newUser,
            },
            {
                onSuccess: () => {
                    setShowNewEntry(false);
                    toast({
                        text: t('toasts.userCreated'),
                        style: 'confirmation',
                        ms: 5000,
                    });
                },
            },
        );
    };

    const ErrorMessage = {
        'Error: Request failed with status code 409': t('emailAlreadyExists'),
    } as const;

    if (usersQuery.isError) {
        return (
            <Error
                headline={usersQuery.error?.error?.message || t('noPageFound')}
                message={t('goBack')}
                target={{
                    link: routes.casesList.path,
                    text: t('back'),
                }}
            />
        );
    }

    const genderOptions = t('genders', {
        returnObjects: true,
    });

    return (
        <DefaultPageLayout
            title={!editingCustomerUsers ? t('overviewOfAllUsers') : ''}
            headerActions={
                <Button
                    onClick={() => setShowNewEntry(true)}
                    icon={MdAddCircleOutline}
                >
                    {t('createNewUser')}
                </Button>
            }
        >
            {createUserMutation.isError && (
                <Alert
                    alertType="error"
                    headline={
                        ErrorMessage[
                            createUserMutation.error as keyof typeof ErrorMessage
                        ] ?? t('invalidServerResponse')
                    }
                    className="mb-2"
                />
            )}

            <div className="relative">
                <Modal
                    isOpen={showNewEntry}
                    title={t('createNewUser')}
                    onClose={() => setShowNewEntry(false)}
                    data-testid={nonProdDataTestId('popup2')}
                >
                    <form className="py-2.5 space-y-5">
                        <Select
                            label={t('gender')}
                            initialSelection={{
                                id: 1,
                                label: '-',
                                value: undefined,
                            }}
                            options={Object.values(genderOptions).map(
                                (title, index) => ({
                                    id: index,
                                    label: title,
                                    value: title,
                                }),
                            )}
                            onChange={(newGender: string) => {
                                if (newGender) {
                                    setNewUser({
                                        ...newUser,
                                        gender: newGender,
                                    });
                                }
                            }}
                        />

                        <Input
                            label={t('firstname')}
                            onChange={(value) => {
                                setNewUser((oldUser) => ({
                                    ...oldUser,
                                    firstname: value.target.value,
                                }));
                            }}
                            value={newUser.firstname ?? ''}
                            placeholder={t('firstname')}
                            required={true}
                        />

                        <Input
                            label={t('lastname')}
                            onChange={(value) => {
                                setNewUser((oldUser) => ({
                                    ...oldUser,
                                    lastname: value.target.value,
                                }));
                            }}
                            value={newUser.lastname ?? ''}
                            placeholder={t('lastname')}
                            required={true}
                        />

                        <Input
                            label={t('email')}
                            onChange={(value) => {
                                setNewUser((oldUser) => ({
                                    ...oldUser,
                                    email: value.target.value,
                                }));
                            }}
                            value={newUser.email ?? ''}
                            placeholder={t('emailPlaceholder')}
                            required={true}
                        />

                        <Checkbox
                            label={t('admin')}
                            onChange={(newIsAdmin) =>
                                setNewUser((oldUser) => ({
                                    ...oldUser,
                                    isAdmin: newIsAdmin,
                                }))
                            }
                            centered={false}
                            initialIsSelected={newUser.isAdmin}
                        />
                    </form>
                    <div className="flex justify-end space-x-6">
                        <Button
                            level="primaryGhost"
                            type="button"
                            onClick={() => setShowNewEntry(false)}
                            data-testid={nonProdDataTestId(
                                'cancel user creation',
                            )}
                        >
                            {t('closeButton')}
                        </Button>
                        <Button onClick={handleAddNewUser} type="submit">
                            {t('addButton')}
                        </Button>
                    </div>
                </Modal>

                <UserTable
                    headlines={
                        editingCustomerUsers
                            ? [
                                  t('name'),
                                  t('lastReportCreatedAt'),
                                  t('createdAt'),
                              ]
                            : [
                                  t('name'),
                                  t('email'),
                                  t('privilegeLevel'),
                                  t('createdAt'),
                              ]
                    }
                    users={users || []}
                    layoutOptions={{
                        highlightColumns: {
                            firstColBold: true,
                        },
                        textAlign: {
                            3: 'center',
                            4: 'center',
                        },
                    }}
                    tableRowActions={tableRowActions}
                    editingCustomerUsers={editingCustomerUsers}
                />

                <ConfirmationModal
                    body={t('deleteUser.modalText')}
                    cancelButtonText={t('deleteUser.cancelButton')}
                    confirmButtonText={t('deleteUser.confirmButton')}
                    enableConfirm={enableConfirm}
                    isOpen={showModal}
                    radioOptions={{
                        options: t('deleteUser.radioOptions', {
                            returnObjects: true,
                        }),
                        handleSelect: () =>
                            dispatch({ type: 'ENABLE_CONFIRM_BUTTON' }),
                    }}
                    title={t('deleteUser.modalTitle')}
                    handleButtonClick={handleModalEvent}
                />

                {usersQuery.isLoading && <LoadingSpinner />}
            </div>
        </DefaultPageLayout>
    );
};
