import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ConditionalSpotlight, Error, LoadingSpinner } from '_atoms';
import { ConfirmationModal, InfiniteScrollV2 } from '_molecules';
import { CaseInfo, Table } from '_organisms/Table';
import { routes } from '../../../routes';
import {
    useCasesQuery,
    useCustomerQuery,
    useDeleteCaseMutation,
} from '_queries';
import { CaseData, Paging } from '../../../services/dataService';
import { useUserState } from '../../../context/User';
import { useDeleteMutation } from '../../../hooks/useDeleteMutation';
import { formatDate, isCustomerExpired, nonProdDataTestId } from '_utils';
import { USER_GROUP_ADMINISTRATORS } from '../../../services/authenticationService';
import {
    useExtractQueryParams,
    useUpdateSearchState,
} from '../../../hooks/useSearch';
import { SearchBar } from '../../../components/_molecules/SearchBar/SearchBar';
import { TableEmpty } from '_organisms/Table/TableEmpty/TableEmpty';
import { DefaultPageLayout } from '../../../components/Layout/DefaultPageLayout';
import { MdAddCircleOutline } from 'react-icons/md';
import { CaseType } from '../CaseNew';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { useToast } from '../../../context/Toast';

const prepareCases = (
    data: CaseData[],
    caseTypes: Record<CaseType, string>,
    unknownTranslation: string,
): CaseInfo[] =>
    data.map(({ id, title: caseName, option, createdAt }: CaseData) => ({
        id,
        caseName,
        caseType:
            option && option.option && caseTypes[option.option]
                ? caseTypes[option.option]
                : unknownTranslation,
        createdOn: createdAt ? formatDate(createdAt) : '-',
    }));

export const CasesList: FC = () => {
    const { t } = useTranslation();

    const toast = useToast();

    const caseTypes: Record<CaseType, string> = t('caseTypes', {
        returnObjects: true,
    });

    const deleteItemReducer = useDeleteMutation();

    const [{ enableConfirm, showModal }, dispatch] = deleteItemReducer;

    const history = useHistory();

    const [searchState, setSearchState] = useState({
        query: '',
    });
    const [{ userRoles, customerId }] = useUserState();
    const { data: customer } = useCustomerQuery(customerId);
    const getQueryParams = useExtractQueryParams('query', searchState);

    const { data: casesResponse, ...casesQuery } = useCasesQuery(
        getQueryParams(),
    );

    const PAGE_SIZE = 20;
    const [currentPageData, setCurrentPageData] = useState<
        Pick<Paging, 'currentPage' | 'nextPage' | 'previousPage'>
    >({
        currentPage: 1,
        nextPage: undefined,
        previousPage: undefined,
    });

    const [pagingData, setPagingData] = useState<
        Omit<Paging, 'currentPage' | 'nextPage' | 'previousPage'>
    >({
        count: 0,
        pagesCount: 0,
    });

    const [pagingIsLoading, setPagingIsLoading] = useState(false);

    const casesData = useMemo(
        () =>
            casesResponse?.data.slice(
                0,
                currentPageData.currentPage * PAGE_SIZE,
            ) || [],
        [casesResponse, currentPageData],
    );

    const { mutate: mutation } = useDeleteCaseMutation({
        onSuccess: () =>
            toast({
                text: t('toasts.deleteCase'),
                style: 'confirmation',
                ms: 5000,
            }),
        onError: () =>
            toast({
                text: t('toasts.deleteCaseFailed'),
                style: 'error',
                ms: 5000,
            }),
    });

    const handleModalEvent = (event: 'confirm' | 'cancel') => {
        switch (event) {
            case 'confirm':
                dispatch({
                    type: 'CONFIRM_DELETION',
                    mutation,
                });
                break;
            case 'cancel':
                dispatch({ type: 'RESET' });
                break;
        }
    };

    useEffect(() => {
        (() => {
            const count = casesResponse?.data.length ?? 0;
            setPagingData({
                count,
                pagesCount: Math.ceil(count / PAGE_SIZE),
            });
        })();
    }, [casesResponse?.data]);

    const handlePageChange = useCallback(
        (pageNumber: number) => {
            setPagingIsLoading(true);

            setTimeout(() => {
                setCurrentPageData({
                    currentPage: pageNumber,
                    nextPage:
                        pageNumber === pagingData.pagesCount
                            ? undefined
                            : pageNumber + 1,
                    previousPage: pageNumber === 1 ? undefined : pageNumber - 1,
                });
                setPagingIsLoading(false);
            }, 300);
        },
        [pagingData.pagesCount],
    );

    // TODO: fix this seemingly causing the following React warning:
    //       "Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state."
    useUpdateSearchState('query', setSearchState);

    const tableRowActions = userRoles.includes(USER_GROUP_ADMINISTRATORS)
        ? [
              {
                  id: 'editCaseAction',
                  label: t('editCaseAction'),
                  callback: (caseId: string) => {
                      history.push(
                          generatePath(routes.editCase.path, { caseId }),
                      );
                  },
              },
              {
                  id: 'deleteCaseAction',
                  label: t('deleteCase.action'),
                  callback: (caseId: string) => {
                      dispatch({
                          type: 'SET_DELETE_PARAMS',
                          params: { caseId },
                      });
                  },
              },
          ]
        : undefined;

    return (
        <DefaultPageLayout
            title={casesQuery.isError ? t('uhOh') : t('overviewOfAllCases')}
            headerActions={
                userRoles.includes(USER_GROUP_ADMINISTRATORS) && (
                    <Link to={routes.caseNew.path}>
                        <ConditionalSpotlight
                            targetName="onboardingCaseListCreatePosition"
                            condition={casesQuery.isSuccess}
                        >
                            <Button
                                disabled={isCustomerExpired(
                                    customer?.expiresAt,
                                )}
                                data-testid={nonProdDataTestId('create case')}
                                icon={MdAddCircleOutline}
                            >
                                {t('createNewCase')}
                            </Button>
                        </ConditionalSpotlight>
                    </Link>
                )
            }
        >
            {casesQuery.isError ? (
                <Error
                    headline={t('noPageFound')}
                    message={t('goBack')}
                    target={{
                        link: routes.casesList.path,
                        text: t('back'),
                    }}
                />
            ) : (
                <>
                    <div className="mb-8">
                        <SpotlightTarget name="onboardingCaseListSearch">
                            <SearchBar
                                query={searchState.query}
                                onSearchChange={setSearchState}
                            />
                        </SpotlightTarget>
                    </div>

                    {casesQuery.isLoading && <LoadingSpinner />}

                    {casesQuery.isSuccess ? (
                        <div>
                            {casesData.length ? (
                                <>
                                    <Table
                                        headlines={[
                                            t('caseName'),
                                            t('caseType'),
                                            t('dateCreated'),
                                        ]}
                                        items={prepareCases(
                                            casesData,
                                            caseTypes,
                                            t('unknown'),
                                        )}
                                        linkOption={{
                                            name: t('open'),
                                            href: routes.caseShow.path,
                                            param: 'caseId',
                                        }}
                                        layoutOptions={{
                                            highlightColumns: {
                                                firstColBold: true,
                                            },
                                        }}
                                        tableRowActions={tableRowActions}
                                    />
                                    <ConfirmationModal
                                        body={t('deleteCase.modalText')}
                                        cancelButtonText={t(
                                            'deleteCase.cancelButton',
                                        )}
                                        confirmButtonText={t(
                                            'deleteCase.confirmButton',
                                        )}
                                        enableConfirm={enableConfirm}
                                        isOpen={showModal}
                                        radioOptions={{
                                            options: t(
                                                'deleteCase.radioOptions',
                                                {
                                                    returnObjects: true,
                                                },
                                            ),
                                            handleSelect: () =>
                                                dispatch({
                                                    type: 'ENABLE_CONFIRM_BUTTON',
                                                }),
                                        }}
                                        title={t('deleteCase.modalTitle')}
                                        handleButtonClick={handleModalEvent}
                                    />
                                    <InfiniteScrollV2
                                        paging={{
                                            currentPage:
                                                currentPageData.currentPage,
                                            nextPage: currentPageData.nextPage,
                                            previousPage:
                                                currentPageData.previousPage,
                                            count: pagingData.count,
                                            pagesCount: pagingData.pagesCount,
                                        }}
                                        isLoading={pagingIsLoading}
                                        setPageNumber={(page) =>
                                            handlePageChange(page)
                                        }
                                    />
                                </>
                            ) : (
                                <TableEmpty
                                    headline={t('noResultHeadline')}
                                    message={t('noResultText')}
                                />
                            )}
                        </div>
                    ) : null}
                </>
            )}
        </DefaultPageLayout>
    );
};
