import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import {
    MdCancel,
    MdCheckCircleOutline,
    MdInfo,
    MdOutlineReportProblem,
} from 'react-icons/md';
import { Paragraph } from '_atoms';

type AlertType = 'error' | 'warning' | 'success' | 'info' | 'disclamer';

type AlertProps = {
    headline?: React.ReactNode;
    message?: React.ReactNode;
    alertType: AlertType;
    className?: string;
};

export const Alert: FC<AlertProps> = ({
    headline,
    message,
    alertType,
    className,
}: AlertProps) => {
    const getIcon = useMemo(() => {
        switch (alertType) {
            case 'error':
                return <MdCancel />;

            case 'warning':
                return <MdOutlineReportProblem />;

            case 'success':
                return <MdCheckCircleOutline />;

            case 'info':
                return <MdInfo />;

            default:
                break;
        }
    }, [alertType]);

    const getColor = useMemo(() => {
        switch (alertType) {
            case 'error':
                return {
                    bg: 'bg-red-100',
                    icon: 'text-error-2',
                    text: 'text-error-1',
                    border: 'border border-error-2',
                };

            case 'warning':
                return {
                    bg: 'bg-yellow-100',
                    icon: 'text-yellow-500',
                    text: 'text-yellow-800',
                };

            case 'success':
                return {
                    bg: 'bg-green-100',
                    icon: 'text-green-500',
                    text: 'text-green-800',
                };

            case 'info':
                return {
                    bg: 'bg-blue-100',
                    icon: 'text-blue-500',
                    text: 'text-blue-800',
                };

            case 'disclamer':
                return {
                    bg: 'bg-transparent',
                    icon: '',
                    text: 'text-error-2',
                    border: 'border border-error-2',
                };

            default:
                return {
                    bg: '',
                    icon: '',
                    text: '',
                };
        }
    }, [alertType]);

    return (
        <div
            className={classnames(
                className,
                getColor.bg,
                getColor.border || '',
                'flex p-4 rounded-md',
            )}
        >
            <div className={`${getColor.icon} mr-3.5`}>{getIcon}</div>

            <div>
                {headline && (
                    <Paragraph
                        size="default"
                        weight="medium"
                        lineHeight="default"
                        className={`${getColor.text} mt-0.5 mb-2`}
                    >
                        {headline}
                    </Paragraph>
                )}

                {message && (
                    <Paragraph
                        size="default"
                        weight="normal"
                        lineHeight="default"
                        className={getColor.text}
                    >
                        {message}
                    </Paragraph>
                )}
            </div>
        </div>
    );
};
