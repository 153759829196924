import { CompanySource, EntrepreneurialActivities } from '_types';
import { ProfileDataEducation } from '../../../../../services/dataService';
import { TimelineGroup, TimelineItem } from './types';
import { getValidSource } from '../helpers';

interface Timeline {
    groups: TimelineGroup[];
    items: TimelineItem[];
}

interface SeenGroups {
    [name: string]: {
        id: number;
        startTime: number;
    };
}

interface GetTimelineProps {
    companies?: EntrepreneurialActivities;
    educations?: ProfileDataEducation[];
}

export const getTimeline = ({
    companies,
    educations,
}: GetTimelineProps): Timeline => {
    let groupId = 0;
    let itemId = 0;
    const seenGroupsMap: SeenGroups = {};
    const groups: Timeline['groups'] = [];
    const items: Timeline['items'] = [];

    Object.entries(companies ?? {}).forEach(([source, entries]) => {
        // const validSource = getValidSource(source);
        // if (!validSource) {
        //     return;
        // }

        entries.forEach((company) => {
            const startTime = new Date(company.from || 0).getTime();
            const endTime = (
                company.to ? new Date(company.to) : new Date()
            ).getTime();

            items.push(
                getItemProps({
                    startTime,
                    endTime,
                    id: itemId,
                    groupId,
                    name: `🏢 ${company.role} @ ${company.companyName}`,
                    isCurrent: company.isCurrentPosition,
                    source,
                }),
            );

            itemId++;

            if (!(company.companyName in seenGroupsMap)) {
                seenGroupsMap[company.companyName] = {
                    id: groupId,
                    startTime,
                };
                groups.push({
                    id: seenGroupsMap[company.companyName].id,
                    title: company.companyName,
                    stackItems: true,
                });

                groupId++;
            }
        });
    });

    educations?.forEach((education) => {
        const validSource = getValidSource(education.sources[0]);
        if (!validSource) {
            return;
        }

        const startTime = new Date(
            education.value.date_range?.start || 0,
        ).getTime();

        const endTime = new Date(
            education.value.date_range?.end || 0,
        ).getTime();

        items.push(
            getItemProps({
                startTime,
                endTime,
                id: itemId,
                groupId,
                name: `🏫 ${education.value.degree}`,
                isCurrent: false,
                source: validSource,
            }),
        );

        itemId++;

        if (
            !(education.value.display in seenGroupsMap) &&
            education.value.school
        ) {
            seenGroupsMap[education.value.school] = {
                id: groupId,
                startTime,
            };
            groups.push({
                id: groupId,
                title: education.value.school?.toUpperCase(),
                stackItems: true,
            });
            groupId++;
        }
    });

    return { groups, items };
};

interface Item {
    startTime: number;
    endTime: number;
    name: string;
    id: number;
    groupId: number;
    isCurrent: boolean;
    source: CompanySource;
}

const getItemColors = (source: CompanySource) => {
    if (source === 'companyhouse') {
        return {
            background: '#fbcfe8',
            color: '#db2777',
            highlighted: 'linear-gradient(to right, #fbcfe8, #dedede00)',
        };
    }

    if (source === 'orbis') {
        return {
            background: '#c3ecff',
            color: '#3ca4d4',
            highlighted: 'linear-gradient(to right, #c3ecff, #dedede00)',
        };
    }

    if (source === 'pipl' || source === 'shadowdragon') {
        return {
            background: '#fecaca',
            color: '#dc2626',
            highlighted: 'linear-gradient(to right, #fecaca, #dedede00)',
        };
    }

    // e.g. source === 'userInput'
    return {
        background: '#8ed2c6',
        color: '#173548',
        highlighted: 'linear-gradient(to right, #8ed2c6, #dedede00)',
    };
};

const getItemProps = ({
    id,
    groupId,
    name,
    startTime,
    endTime,
    isCurrent,
    source,
}: Item): Timeline['items'][number] => ({
    id,
    group: groupId,
    title: name,
    start_time: startTime,
    end_time: endTime,
    itemProps: {
        'aria-hidden': true,
        className: 'weekend',
        style: {
            fontSize: '1rem',
            fontWeight: 'bolder',
            border: 'none',
            borderTop: startTime ? '3px solid' : '3px dashed',
            borderBottom: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            color: getItemColors(source).color,
            background:
                startTime || (endTime && isCurrent)
                    ? getItemColors(source).background
                    : getItemColors(source).highlighted,
        },
    },
});

export const getInitialTimelineStartTime = (
    companies?: EntrepreneurialActivities,
    educations?: ProfileDataEducation[],
): number => {
    let minStartTime = new Date().getTime() + 1000000;

    Object.values(companies ?? {})
        .flat()
        .forEach(({ from }) => {
            const startTime = from ? new Date(from).getTime() : undefined;

            if (startTime && startTime < minStartTime) {
                minStartTime = startTime;
            }
        });

    educations?.forEach(({ value }) => {
        const startTime = value.date_range?.start
            ? new Date(value.date_range?.start).getTime()
            : undefined;

        if (startTime && startTime < minStartTime) {
            minStartTime = startTime;
        }
    });

    return minStartTime > new Date().getTime()
        ? new Date('2000-01-01').getTime()
        : minStartTime;
};
