import { useMutation, UseMutationResult } from 'react-query';
import { suspicions } from '../../services/nestApiService';
import { CreateSuspicionItemBody } from '../../types/SuspicionItem';

export const useCreateSuspicionItemMutation = (): UseMutationResult<
    unknown,
    unknown,
    CreateSuspicionItemBody
> => {
    return useMutation(
        async (payload: CreateSuspicionItemBody) =>
            suspicions.items.create(payload),
        {
            retry: (attempt) => attempt < 3,
            retryDelay: (attempt) => 2000 * Math.pow(2, attempt - 1),
        },
    );
};
