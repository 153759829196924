import { Cell, LabelList, Pie, PieChart } from 'recharts';
import React, { FC } from 'react';
import { Data } from '../../../features/dashboard/CV/CVAnalysis/types';

interface DonutPieChartProps {
    data: Data[];
    colors: string[];
    radius?: number;
    compactView?: boolean;
}

export const DonutPieChart: FC<DonutPieChartProps> = ({
    data,
    colors,
    compactView = 1,
    radius = 36,
}) => {
    const size = radius * 2;

    const sortedData = data.sort((a, b) => b.value - a.value);

    return (
        <div className="flex items-center justify-center flex-shrink">
            <PieChart
                width={size}
                height={size}
                margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
            >
                <Pie
                    data={sortedData}
                    cx="50%"
                    cy="50%"
                    innerRadius={radius * 0.6}
                    outerRadius={radius}
                    paddingAngle={sortedData.length === 1 ? 0 : 2}
                    dataKey="value"
                >
                    {sortedData.map((_, index) => (
                        <Cell key={index} fill={colors[index]} />
                    ))}
                    {!compactView && (
                        <LabelList
                            dataKey="value"
                            position="inside"
                            fill="white"
                            fontSize={'0.875rem'}
                        />
                    )}
                </Pie>
            </PieChart>
        </div>
    );
};
