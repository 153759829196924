import React, { FC, useMemo, useState } from 'react';
import { ResultsProps } from '../../Results';
import { ProfileInfo } from '../../RiskAnalysisV2/ProfileInfo';
import { DefaultPageLayout } from '../../../../components/Layout/DefaultPageLayout';
import { useTranslation } from 'react-i18next';
import { Paragraph, RadioGroup } from '_atoms';
import {
    buildChartConfig,
    filterExperience,
    getAverageTimespanInMonthsOfPositions,
} from './helpers';
import { TargetReportSourceType } from '@indicium/common/src/types/Report/SharedTypes';
import { CvCard, CVCardSkeleton } from './Components/CvCard';
import { format, parseISO } from 'date-fns';

const osintSources: TargetReportSourceType[] = [
    'companyhouse',
    'orbis',
    'pipl',
    'digitalclues',
    'firmenabc',
    'indicium',
    'zefix',
    'complianceCatalyst',
    'icijLeaks',
    'google',
    'shadowdragon',
];

const userInputSources: TargetReportSourceType[] = ['userInput'];

type GroupSource = 'cv' | 'osint' | 'all';

const sourceLists: Record<GroupSource, TargetReportSourceType[]> = {
    cv: userInputSources,
    osint: osintSources,
    all: osintSources.concat(userInputSources),
};

const sourceGroups: GroupSource[] = ['cv', 'osint', 'all'];

export const CvAnalysis: FC<ResultsProps> = ({
    targetData,
    targetImages,
    targetLoading,
}) => {
    const { t } = useTranslation();

    const targetFullName = [
        targetData?.firstname,
        targetData?.middlename,
        targetData?.lastname,
    ]
        .filter(Boolean)
        .join(' ');

    const sourceGroupOptions = useMemo(
        () =>
            sourceGroups.map((group, index) => ({
                id: index + 1,
                label: t(`cVAnalysis.sourceGroupOptions.${group}`),
                value: group,
            })),
        [t],
    );

    const [activeSourceGroup, setActiveSourceGroup] =
        useState<GroupSource>('all');

    const activeSources = sourceLists[activeSourceGroup];

    const targetCreationDate = targetData?.createdAt
        ? format(parseISO(targetData.createdAt), 'yyyy-MM-dd')
        : undefined;

    const workExperienceBySource = Object.entries(
        targetData?.entrepreneurialActivities ?? {},
    )
        .filter(([source]) =>
            activeSources.includes(source as TargetReportSourceType),
        )
        .flatMap(([, workExperience]) => workExperience);

    const workExperience = workExperienceBySource
        .map((workExperience) => ({
            companyName: workExperience.companyName,
            role: workExperience.role ?? 'unknown',
            start: workExperience.from,
            end: workExperience.to ?? targetCreationDate,
        }))
        .filter(filterExperience);

    const eductionBySource =
        targetData?.profile?.data.educations?.filter((education) =>
            education.sources.some((source) => activeSources.includes(source)),
        ) ?? [];

    const education = eductionBySource
        .map((education) => ({
            start: education.value.date_range?.start,
            end: education.value.date_range?.end ?? targetCreationDate,
        }))
        .filter(filterExperience);

    const uniqueCompanies = Array.from(
        new Set(workExperienceBySource.flatMap((work) => work.companyName)),
    );

    const averageTimespanInMonthsOfPosition =
        getAverageTimespanInMonthsOfPositions(workExperience);

    const missingWorkRanges =
        workExperience.length !== workExperienceBySource.length;

    const missingEducationRanges = education.length !== eductionBySource.length;

    const chartConfig = useMemo(
        () =>
            buildChartConfig(
                averageTimespanInMonthsOfPosition,
                education,
                workExperience,
                uniqueCompanies,
                missingWorkRanges,
                missingEducationRanges,
                t,
            ),
        [
            averageTimespanInMonthsOfPosition,
            education,
            workExperience,
            uniqueCompanies,
            missingWorkRanges,
            missingEducationRanges,
            t,
        ],
    );

    return (
        <DefaultPageLayout
            title={t('cVAnalysis.title')}
            headerActions={
                <ProfileInfo
                    name={targetFullName}
                    createdAt={targetData?.profile?.createdAt}
                    imageUrl={targetImages[0]}
                />
            }
        >
            <div className="flex flex-row gap-8 items-center justify-center pb-4">
                <Paragraph
                    color="light"
                    weight="bold"
                    size="small"
                    className="font-jost"
                >
                    {t('cVAnalysis.selectSourceGroup')}
                </Paragraph>
                <RadioGroup
                    options={sourceGroupOptions}
                    onSelect={(value) =>
                        setActiveSourceGroup(value as GroupSource)
                    }
                    selected={activeSourceGroup}
                />
            </div>
            <div className="grid grid-cols-3 gap-4 overflow-visible">
                {chartConfig.map((statistic) => (
                    <CvCard key={statistic.labels.title} {...statistic} />
                ))}
                {targetLoading &&
                    Array(4)
                        .fill(null)
                        .map((_, index) => (
                            <CVCardSkeleton key={index} index={index} />
                        ))}
            </div>
        </DefaultPageLayout>
    );
};
