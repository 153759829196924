import { FC } from 'react';
import classnames from 'classnames';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { Headline, Paragraph } from '_atoms';
import { ReactComponent as NotFound } from '../../../../images/dataNotFound.svg';

type ErrorProps = {
    headline?: string;
    message?: string;
    // target?: {
    //     link: string;
    //     text: string;
    // };
    className?: string;
    imageHeight?: number;
};

export const TableEmpty: FC<ErrorProps> = ({
    headline,
    message,
    // target,
    className,
    imageHeight = 264, // jfyi this is the height of the actual svg
}: ErrorProps) => {
    return (
        <div
            className={classnames(
                className,
                `flex flex-col justify-center items-center space-y-3 text-center`,
                !className?.includes('pt-') && 'pt-9',
            )}
        >
            <NotFound className="mx-auto" height={imageHeight} />
            {headline && <Headline Level="h3">{headline}</Headline>}
            {message && (
                <Paragraph className="max-w-100" color="light">
                    {message}
                </Paragraph>
            )}

            {/* NOTE: Not sure what this was meant for. Maybe we can simply remove it */}
            {/*{target ? (*/}
            {/*    <div className="row mt-5">*/}
            {/*        <div className="col-12 flex space-x-4 justify-end">*/}
            {/*            <Link to={target?.link}>*/}
            {/*                <Button*/}
            {/*                    className="w-52"*/}
            {/*                    type="submit"*/}
            {/*                    level="primary"*/}
            {/*                    disabled={false}*/}
            {/*                >*/}
            {/*                    {target?.text}*/}
            {/*                </Button>*/}
            {/*            </Link>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*) : null}*/}
        </div>
    );
};
